import React, { forwardRef, useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { sendEvent } from '@src/utils/amplitude';
import * as styles from './featuresBlockInfo.module.scss';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const FeaturesBlockInfo = forwardRef(
	(
		{
			name,
			title,
			description,
			className,
			style,
			videoMp4 = '',
			videoWebm = '',
			videoPoster = '',
			isHighlights = false,
			onTryBtnClick,
			tryBtnEventLocation,
			imagePath,
			difLayout = false,
			type,
		},
		ref
	) => {
		const account = useSelector((state) => state.authReducer);
		const [showVideoEl, setShowVideoEl] = useState(false);
		const intl = useIntl();

		return (
			<div className={[styles.container, className].join(' ')} style={style} ref={ref}>
				<div className={[styles.blockInfoContent, difLayout ? styles.diffContentWrapper : ''].join(' ')}>
					<div className={[styles.contentWrapper, difLayout ? styles.sticky : ''].join(' ')}>
						{/*<div className={[styles.name].join(' ')}>{name}</div>*/}

						<h2 className={styles.title}>{title}</h2>
						{description && <p className={styles.descr}>{description}</p>}
					</div>
				</div>

				<div className={styles.imageBlock}>
					{type && type === 'clipsHighlights' ? (
						<div className={styles.video}>
							<iframe
								className={styles.image}
								src="https://www.youtube-nocookie.com/embed/BuoQY8_Z1pY?controls=0&loop=1&autoplay=1&mute=1&vq=hd720&enablejsapi=1&fs=0&loop=1&modestbranding=1&playlist=BuoQY8_Z1pY&playsinline=1&rel=0&vq=hd720"
								title="Scope.gg Highlights"
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope"
								allowFullScreen
							/>
						</div>
					) : (
						<img src={imagePath} className={styles.image} />
					)}
				</div>
			</div>
		);
	}
);

export default FeaturesBlockInfo;
